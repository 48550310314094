import * as React from 'react';
import { css } from '@emotion/react';
import ChildSafetyContent from '@src/components/organisms/ChildSafetyContent';
import TopPageFooter from '@src/components/organisms/TopPage/Footer';
import TopPageHeader from '@src/components/organisms/TopPage/Header';
import { useDocumentTitle, usePageLayout } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { ROUTES } from '@src/shared/routes';

const Template = () => {
  const { isMobileView } = usePageLayout();
  useDocumentTitle({ title: 'pageTitle.Child Safety' });

  return (
    <div css={styles.wrapper}>
      <div css={styles.container}>
        <TopPageHeader css={styles.header} href={ROUTES.ROOT} />
        <ChildSafetyContent />
        {!isMobileView && <TopPageFooter css={styles.footer} />}
      </div>
    </div>
  );
};

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    width: 856,
    alignItems: 'center',
  }),
  footer: css({
    width: '100%',
  }),
  header: css({
    paddingTop: 16,

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      backgroundColor: THEME.colors.white,
    },
  }),
  wrapper: css({
    width: '100%',
    minHeight: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
  }),
};

export default Template;
