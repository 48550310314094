import * as React from 'react';
import { APP_NAME } from '@src/libs/constant';
import * as StyledComponents from './StyledComponents';

const PrivacyContentEN = () => (
  <StyledComponents.Wrapper>
    <StyledComponents.PrivacyTitle title="Child Safety and Abuse Prevention Policy" />
    <StyledComponents.PrivacyText>
      {`AnyMind Group Pte. Ltd. (“AnyMind”, "us", "we", or "our") operates "${APP_NAME}"`}&nbsp;
      <StyledComponents.Anchor href="https://app.any-creator.com/" target="_blank">
        https://app.any-creator.com/
      </StyledComponents.Anchor>
      &nbsp;(the "AnyCreator Service"). We care about your rights and we acknowledge that your data is yours. Please
      take your time to have a look through our Child Safety and Abuse Prevention Policy. If you have any questions
      please do not hesitate to contact us by sending an email to&nbsp;
      <StyledComponents.Anchor href="mailto:info@anymindgroup.com" target="_blank">
        info@anymindgroup.com
      </StyledComponents.Anchor>
    </StyledComponents.PrivacyText>

    <StyledComponents.PrivacyText subTitle="Introduction">
      At AnyMind Group Pte. Ltd., we are committed to creating a safe and secure environment for all our users,
      especially children. This Child Safety and Abuse Prevention Policy outlines our commitment to preventing the
      dissemination of harmful content and describes the steps we take to address any potential abuse. Our goal is to
      protect children from exploitation and abuse while ensuring that all users can engage with our services safely.
    </StyledComponents.PrivacyText>

    <StyledComponents.PrivacyText subTitle="Scope">
      This policy applies to all users of our [app/platform/website] and covers:
      <StyledComponents.ListWrapper>
        <StyledComponents.ListItem>
          Prevention of child sexual abuse material (CSAM) and related harmful content.
        </StyledComponents.ListItem>
        <StyledComponents.ListItem>
          Clear guidelines for reporting and handling incidents related to child safety.
        </StyledComponents.ListItem>
        <StyledComponents.ListItem>
          Our internal procedures for reviewing and responding to potential violations.
        </StyledComponents.ListItem>
      </StyledComponents.ListWrapper>
    </StyledComponents.PrivacyText>

    <StyledComponents.PrivacyText subTitle="Prohibited Content">
      <StyledComponents.ListWrapper>
        <StyledComponents.ListItem>
          <b>Child Sexual Abuse Material (CSAM):</b> Under no circumstances will our platform tolerate any content that
          exploits or endangers children.
        </StyledComponents.ListItem>
        <StyledComponents.ListItem>
          <b>Exploitation and Grooming:</b> Any form of grooming or exploitation aimed at minors is strictly prohibited.
        </StyledComponents.ListItem>
        <StyledComponents.ListItem>
          <b>Inappropriate Interactions:</b> Content or behavior that could lead to the exploitation or abuse of
          children is not allowed.
        </StyledComponents.ListItem>
      </StyledComponents.ListWrapper>
    </StyledComponents.PrivacyText>

    <StyledComponents.PrivacyText>
      All content on our platform is subject to strict review. We have implemented advanced content filtering and
      moderation systems to prevent the upload or sharing of prohibited content.
    </StyledComponents.PrivacyText>

    <StyledComponents.PrivacyText subTitle="Reporting Mechanisms">
      We believe that prevention starts with transparency and quick action. We have established the following reporting
      procedures:
    </StyledComponents.PrivacyText>

    <StyledComponents.PrivacyText subTitle="In-App Reporting">
      <StyledComponents.ListWrapper>
        <StyledComponents.ListItem>
          <b>Contact Area:</b> A prominently placed “Contact” area is available on the app. Users can quickly flag any
          content they find concerning.
        </StyledComponents.ListItem>
        <StyledComponents.ListItem>
          <b>Anonymity Option:</b> Reports can be submitted anonymously if the reporter prefers not to reveal their
          identity.
        </StyledComponents.ListItem>
      </StyledComponents.ListWrapper>
    </StyledComponents.PrivacyText>

    <StyledComponents.PrivacyText subTitle="External Reporting">
      <StyledComponents.ListWrapper>
        <StyledComponents.ListItem>
          <b>Email Contact:</b> For users who prefer to report via email, please contact at&nbsp;
          <StyledComponents.Anchor href="mailto:info@anymindgroup.com" target="_blank">
            info@anymindgroup.com
          </StyledComponents.Anchor>
        </StyledComponents.ListItem>
      </StyledComponents.ListWrapper>
    </StyledComponents.PrivacyText>

    <StyledComponents.PrivacyText subTitle="What to Include in Your Report">
      <StyledComponents.ListWrapper>
        <StyledComponents.ListItem>A clear description of the issue.</StyledComponents.ListItem>
        <StyledComponents.ListItem>Any relevant screenshots or links.</StyledComponents.ListItem>
        <StyledComponents.ListItem>
          Your contact information (if you are comfortable providing it).
        </StyledComponents.ListItem>
      </StyledComponents.ListWrapper>
    </StyledComponents.PrivacyText>

    <StyledComponents.PrivacyText subTitle="Our Response Process">
      <StyledComponents.PrivacyText>Upon receiving a report, our team will:</StyledComponents.PrivacyText>
      <StyledComponents.ListWrapper>
        <StyledComponents.ListItem>
          Review the Report: Evaluate the content to determine whether it violates our policies.
        </StyledComponents.ListItem>
        <StyledComponents.ListItem>
          Immediate Action: Remove or restrict access to content that poses an immediate risk to children.
        </StyledComponents.ListItem>
        <StyledComponents.ListItem>
          Investigation: Work with internal moderators and, when necessary, external authorities to investigate the
          report.
        </StyledComponents.ListItem>
        <StyledComponents.ListItem>
          Feedback: Provide follow-up communication to the reporting party (if contact details are provided) regarding
          the outcome, while maintaining confidentiality.
        </StyledComponents.ListItem>
      </StyledComponents.ListWrapper>
    </StyledComponents.PrivacyText>

    <StyledComponents.PrivacyText subTitle="Compliance and Cooperation">
      We are fully committed to cooperating with law enforcement agencies and regulatory bodies in the investigation of
      any incidents involving child exploitation or abuse. Our procedures are in line with local and international laws,
      and we regularly review our practices to ensure the highest standards of child protection.
    </StyledComponents.PrivacyText>
    <StyledComponents.PrivacyText subTitle="Continuous Improvement">
      Child safety is a continuously evolving area. We review and update this policy regularly to incorporate new
      technologies, comply with emerging legal requirements, and integrate feedback from our community. Our commitment
      to creating a safe environment for all users remains our highest priority.
    </StyledComponents.PrivacyText>
    <StyledComponents.PrivacyText subTitle="Contact Information">
      If you have any questions about this policy or require further assistance, please contact our team:
      <StyledComponents.Anchor href="mailto:info@anymindgroup.com" target="_blank">
        info@anymindgroup.com
      </StyledComponents.Anchor>
    </StyledComponents.PrivacyText>
    <StyledComponents.PrivacyText>
      By using our [app/platform/website], you agree to abide by this policy. We thank you for your commitment to
      keeping our community safe.
    </StyledComponents.PrivacyText>
  </StyledComponents.Wrapper>
);

export default PrivacyContentEN;
